import { graphql } from "gatsby";
import styled from 'styled-components'
import React from "react";
import SEO from "../components/seo";
import { MarkdownPageContainer } from "../templates/markdownPage";

interface PricingPageProps {
  data: {
    cms: {
      pricingPage: { 
        content: {
          html: string
        }
      }
    }  
  }
}

const PricingPage: React.FC<PricingPageProps> = ({ data }) => {
  const pricingPage = data.cms.pricingPage;

  return (
    <CustomMarkdownPageContainer>
      <PageTitle>Cennik</PageTitle>
      <div dangerouslySetInnerHTML={{ __html: pricingPage.content.html }} />
      <SEO />
    </CustomMarkdownPageContainer> 
  )
} 

export const query = graphql`
  query {
    cms {
      pricingPage(stage: PUBLISHED, where: {type: "main"}) {
        content {
          html
        }
      }
    }
  }
`;

const CustomMarkdownPageContainer = styled(MarkdownPageContainer)`
  p {
    min-height: 1em;
  }

  ul {
    padding-left: ${(props) => props.theme.space[3]}px;
  }

  table {
    width: 100%;
  }

  tr {
    display: flex;
    width: 375px;

    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
      width: 100%;
    }

    & > td:nth-child(1) {
      flex: 8;

      @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
        flex: 7;
      }
    }

    & > td:nth-child(2) {
      flex: 2;
      text-align: right;
    }
  }
`

export const PageTitle = styled.h1`
  text-align: center;
  margin-top: ${(props) => props.theme.space[4]}px;
  margin-bottom: ${(props) => props.theme.space[6]}px;
  font-size: ${(props) => props.theme.fontSizes[6]}px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;

export default PricingPage;